import { isEmpty } from 'lodash';

export async function createPromiseEvaluatedToComponentProps(
  audienceSegment,
  channel,
  userId,
  audienceDistributionService,
  isRequestCancelledService,
  trackTooNarrow,
  hideAudiencePreview,
  householdDistributionService,
  showAudienceIpCount
) {
  const isEmptyAudienceSegment = isEmpty(audienceSegment);
  const householdData = showAudienceIpCount ? await householdDistributionService(audienceSegment, channel) : 0;
  // hideAudiencePreview - in case of hisense AU we don't want to send the demographic call to audience service
  if (isEmptyAudienceSegment || hideAudiencePreview) {
    const response = {
      hasData: false,
      isTooWide: false,
      isTooNarrow: false,
      isEntitiesLimit: false,
      isError: false,
      householdPopulationDist: householdData,
    };
    if (isEmptyAudienceSegment) {
      response.summary = 'All Population';
    }
    return response;
  }

  const data = await audienceDistributionService(audienceSegment, { channel, userId });
  return convertPromiseResultsToComponentProps(data, householdData, isRequestCancelledService, trackTooNarrow);
}

export function convertPromiseResultsToComponentProps(data, householdData, isRequestCancelledService, trackTooNarrow) {
  const MIN_AUDIENCE_SIZE = 1000;

  if (!data) return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: true };

  if (isRequestCancelledService(data)) return false; // cancel

  if (data.status === 'ok') {
    const a = {
      hasData: true,
      isTooWide: false,
      isTooNarrow: false,
      isEntitiesLimit: false,
      isError: false,
      ageDist: data.distribution.age,
      genderDist: data.distribution.gender,
      populationDist:
        data.audienceSize.population < MIN_AUDIENCE_SIZE ? MIN_AUDIENCE_SIZE : data.audienceSize.population,
      householdPopulationDist: householdData,
    };
    console.log('=======================', a);
    return a;
  }

  switch (data.error.reason) {
    case 'no_results':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'selectionTooNarrow':
      //trackTooNarrow();
      //return { hasData: false, isTooWide: false, isTooNarrow: true, isEntitiesLimit: false, isError: false };
      // temp solution to allow audience without profiles
      return {
        hasData: true,
        isTooWide: false,
        isTooNarrow: false,
        isEntitiesLimit: false,
        isError: false,
        ageDist: [
          {
            label: '13-17',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '18-20',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '21-24',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '25-34',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '35-44',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '45-49',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '50-54',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '55-64',
            value: 0,
            displayValue: '0%',
          },
          {
            label: '65+',
            value: 0,
            displayValue: '0%',
          },
        ],
        genderDist: { male: { value: 0, displayValue: '0%' }, female: { value: 0, displayValue: '0%' } },
        populationDist: 0,
        householdPopulationDist: 0,
      };
    case 'selectionTooWide':
      return { hasData: false, isTooWide: true, isTooNarrow: false, isEntitiesLimit: false, isError: false };
    case 'too_many_entities':
      return { hasData: false, isTooWide: false, isTooNarrow: false, isEntitiesLimit: true, isError: false };
    default:
      return { hasData: false };
  }
}
